import * as React from 'react';
const IconSeznamLogoEskoCervena = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    viewBox="0 0 36 37"
    {...props}
  >
    <path
      fill="#C00"
      d="M1.787 36c6.484 0 32.915-3.09 32.915-11.363 0-5.04-6.752-7.444-9.526-8.25-5.286-1.533-9.574-3.115-9.574-4.502 0-1.386 3.004-1.677 6.229-2.39 4.295-.95 6.102-1.474 6.102-3.758 0-1.488-1.143-3.304-1.724-4.086C25.195.288 25.034 0 24.703 0c-.716 0-.15.927-8.231 2.29-5.119.863-11.932 3.168-11.932 7.836 0 4.667 6.092 6.833 12.192 9.041 6.286 2.275 11.542 3.106 11.542 6.735 0 5.788-24.84 9.441-26.45 9.709-.753.125-.63.389-.038.389z"
    />
  </svg>
);
export default IconSeznamLogoEskoCervena;
