export const FilterOfToysTranslationsCs = {
  Toys: 'Hračky',
  'toys-for-baby': 'Hračky pro miminka',
  'dolls-and-pups': 'Panenky a panenky',
  'stuffed-toys': 'Měkké hračky',
  constructors: 'Stavitelé',
  'car-model': 'Auta, modely, technika',
  'for-recreation': 'Pro aktivní odpočinek',
  'board-games-and-puzzles': 'Stolní hry, skládačky',
  'toy-weapon': 'Hračkové zbraně',
  'creativity-and-development': 'Kreativita a rozvoj',
  'school-supplies': 'Školní potřeby',
  'children`s-transport': 'Dětská doprava',
  'robots-and-transformers': 'Roboty a transformery',
};
