export const SectionAnswersTranslationsUk = {
  'Question-1': 'Як взяти речі в оренду?',
  'Answer-1':
    'Щоб орендувати речі на платформі AniraK…, вам спочатку потрібно зареєструватися. Ми проведемо вас через необхідні перевірки безпеки, і вам буде запропоновано створити профіль із ключовою інформацією облікового запису. Як тільки ви зареєструєтесь, ви зможете орендувати уподобанні речі, а також запропонувати для оренди свої речі.',

  'Question-2': 'Як дізнатися, чи буде сукня доступна на потрібну дату?',
  'Answer-2':
    'Ви можете побачити, чи буде доступний товар, на потрібну вам дату у календарі під кожною орендою.',
  'Question-3': 'Чи можна забронювати сукню заздалегідь?',
  'Answer-3':
    'Звичайно! Ви можете надіслати запит на бронювання та почекати на схвалення до 24год.',

  'Question-4': 'Скільки триває оренда?',
  'Answer-4':
    'Наш мінімальний термін оренди становить від 5 днів: день відправки вам, два дні для доставки та отримання(це із урахуванням можливих затримок), день події на який саме і потрібна ця річ, день відправки назад.',

  'Question-5': 'А якщо сукня не підходить?',
  'Answer-5':
    'За умови, що опис є 100% точним з точки зору розміру, і позикодавець відповів на будь-які запитання щодо посадки чесно та в міру своїх можливостей, ми не зможемо запропонувати відшкодування за сукні, які сидять не ідеально.',

  'Question-6':
    'Орендодавець попросив мене заплатити йому безпосередньо за межами платформи - це нормально?',
  'Answer-6':
    'AniraK… розроблено, щоб надати вам надійну та безпечну онлайн-платформу для найму. Здійснюючи трансакції за межами платформи, ви більше не будете охоплені нашою підтримкою та системою безпеки. Це є суворим порушенням положень та умов AniraK.., і якщо кредитор просить вас заплатити за межами платформи, будь ласка, негайно повідомте нас про це, задля вашої безпеки.',

  'Question-7': 'Як повернути товар?',
  'Answer-7':
    'Наприкінці періоду оренди просто опакуйте і покладіть орендований товар назад у коробку, у якій він був надісланий.  Напишіть на листку номер посилки та імʼя отримувача (це є в листі «зворотнє відправлення», який ви отримали на електронну пошту), прикріпіть його до коробки та віднесіть до пункту прийому відправок Balikovna. Важливо, оформіть зворотню відправку у вказаний термін, у разі затримки кожного дня нараховуватиметься комісія.',

  'Question-8':
    'Що робити, якщо я запізнився з поверненням взятої на прокат речі?',
  'Answer-8':
    'Ми довіряємо і очікуємо, що клієнти надішлють товари назад у вказаний термін. На жаль, якщо товар не відправиться назад у вказаний термін, щоденно стягуватиметься комісія за прострочення, доки товар не буде відправлено назад, а також це повпливає на ваш рейтинг.',
};
