export const FilterDecorTranslationsEn = {
  Decor: 'Decor',
  accessories: 'Accessories',
  birthday: 'Birthday',
  Christmas: 'Christmas',
  'Theme-holidays': 'Theme holidays',
  textile: 'Textile',
  'blankets-for-baby': 'Blankets for baby',
  linens: 'Bed Linens',
};
