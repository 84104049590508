export const FilterSubjectTranslationsCs = {
  Subject: 'Téma',
  Christmas: 'Vánoce',
  'Ukrainian-symbols': 'Ukrajinská symbolika',
  Animals: 'Zvířata',
  'ballet-&-princesses': 'Balet a princezny',
  Dinosaurs: 'Dinosauri',
  'Flowers-&-butterflies': 'Květiny a motýli',
  Hearts: 'Srdíčka',
  'unicors-&-rainbows': 'Jednorožci a duhy',
};
