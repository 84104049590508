export const FilterSubjectTranslationsUk = {
  Subject: 'Тематика',
  Christmas: 'Різдво',
  'Ukrainian-symbols': 'Українська символіка',
  Animals: 'Звірята',
  'ballet-&-princesses': 'Балет та принцеси',
  Dinosaurs: 'Динозаври',
  'Flowers-&-butterflies': 'Квіти і метелики',
  Hearts: 'Сердечки',
  'unicors-&-rainbows': 'Єдинороги та веселки',
};
