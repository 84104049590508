export const NavigationOverlayTranslationsCs = {
  home: 'Domů',
  womenClothing: 'Dámské oblečení',
  menSuits: 'Pánské obleky',
  childrenClothing: 'Dětské oblečení',
  decorAndToys: 'Dekorace a hračky',
  aboutUs: 'O nás',
  account: 'Účet',
  profile: 'Profil',
  chat: 'Chat',
  favorite: 'Oblíbené',
  offer: 'Nabízím',
  addProduct: 'Přidat produkt',
  rentIn: 'Pronájem',
  myOrders: 'Moje objednávky',
  myPurchases: 'Moje nákupy',
  wallet: 'Můj peněženka',
  basket: 'Košík',
  cardProduct: 'Karta produktu',
  edit: 'Úprava',
};
