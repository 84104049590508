export const ViewOrderProductTranslationsEn = {
  toBackOrders: 'To all orders',
  toBackPurchases: 'To all sales',
  'Order total': 'Order total',
  Shipping: 'Shipping',
  seller: 'Seller',
  'completed orders': 'completed orders',
  'responds within 12 hours': 'Responds within 12 hours',
  'Write to the seller': 'Write to the seller',
  'Shipping information': 'Shipping information',
  Recipient: 'Recipient',
  Address: 'Address',
  City: 'City',
  Street: 'Street',
  Delivery: 'Delivery',
  'Delivery service': 'Delivery service',
  'Delivery type': 'Delivery type',
  Phone: 'Phone',
  'Payment information': 'Payment information',
  Payment: "Payment to seller's card",
};
