export const ProductPageTranslationsEn = {
  sale: 'Sale',
  rental: 'Rental',
  deposit: 'Deposit',
  seller: 'Seller',
  size: 'Size',
  addToCart: 'Add to Cart',
  'Product Description': 'Product Description',
  color: 'Color',
  reviews: 'Reviews',
  'Rental completed': 'Rental completed',
  'Leave a review': 'Leave a review',
  'Rental calendar': 'Rental calendar',

  // color
  white: 'White',
  gray: 'Gray',
  orange: 'Orange',
  turquoise: 'Turquoise',
  green: 'Green',
  black: 'Black',
  bordo: 'Bordo',
  lilac: 'Lilac',
  silvery: 'Silvery',
  yellow: 'Yellow',
  pink: 'Pink',
  blue: 'Blue',
  violet: 'Violet',
  colorful: 'Colorful',
  'light-green': 'Light green',
  coral: 'Coral',
  beige: 'Beige',
  golden: 'Golden',
  red: 'Red',
  khaki: 'Khaki',
  brown: 'Brown',
  'light-blue': 'Light blue',
  peachy: 'Peachy',
  mustard: 'Mustard',

  rentProductFromTo: 'Rent product from {{from}} to {{to}}',
  rentProductFor: 'Rent product for {{date}}',
  'No, choose another date': 'No, choose another date',

  rent: 'Rent',
  buy: 'Buy',
};
