export const NavigationOverlayTranslationsUk = {
  home: 'Головна',
  womenClothing: 'Жіночі наряди',
  menSuits: 'Чоловічі костюми',
  childrenClothing: 'Дитячі наряди',
  decorAndToys: 'Декор та іграшки',
  aboutUs: 'Про нас',
  account: 'Акаунт',
  profile: 'Профіль',
  chat: 'Чат',
  favorite: 'Улюблене',
  offer: 'Пропоную',
  addProduct: 'Додати товар',
  rentIn: 'Орендую',
  myOrders: 'Мої покупки',
  myPurchases: 'Мої продажі',
  wallet: 'Мій гаманець',
  basket: 'Кошик',
  cardProduct: 'Картка товару',
  edit: 'Редагування',
};
