export const FilterSubjectTranslationsEn = {
  Subject: 'Subject',
  Christmas: 'Christmas',
  'Ukrainian-symbols': 'Ukrainian symbols',
  Animals: 'Animals',
  'ballet-&-princesses': 'Ballet and princesses',
  Dinosaurs: 'Dinosaurs',
  'Flowers-&-butterflies': 'Flowers and butterflies',
  Hearts: 'Hearts',
  'unicors-&-rainbows': 'Unicors and rainbows',
};
