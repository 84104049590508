export const arrayChildSizeProduct = [
  '0-50',
  '50-56',
  '62-68',
  '68-74',
  '74-80',
  '80-86',
  '86-92',
  '92-98',
  '98-104',
  '104-110',
  '110-116',
  '116-122',
  '122-128',
  '128-134',
  '134-140',
  '140-146',
  '146-152',
  '152-158',
  '158-164',
  '164-170',
];
