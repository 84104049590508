export const arrayAnswers = [
  {
    titleQuestion: 'Question-1',
    textAnswer: 'Answer-1',
  },
  {
    titleQuestion: 'Question-2',
    textAnswer: 'Answer-2',
  },
  {
    titleQuestion: 'Question-3',
    textAnswer: 'Answer-3',
  },
  {
    titleQuestion: 'Question-4',
    textAnswer: 'Answer-4',
  },
  {
    titleQuestion: 'Question-5',
    textAnswer: 'Answer-5',
  },
  {
    titleQuestion: 'Question-6',
    textAnswer: 'Answer-6',
  },
  {
    titleQuestion: 'Question-7',
    textAnswer: 'Answer-7',
  },
  {
    titleQuestion: 'Question-8',
    textAnswer: 'Answer-8',
  },
];
