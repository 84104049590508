export const ViewOrderProductTranslationsCs = {
  toBackOrders: 'Ke všem nákupům',
  toBackPurchases: 'Ke všem prodejům',
  'Order total': 'Celková částka objednávky',
  Shipping: 'Doprava',
  'Total amount': 'Celková částka',
  seller: 'Prodejce',
  'completed orders': 'dokončené objednávky',
  'responds within 12 hours': 'Reaguje do 12 hodin',
  'Write to the seller': 'Napsat prodejci',
  'Shipping information': 'Informace o dopravě',
  Recipient: 'Příjemce',
  Address: 'Adresa',
  City: 'Město',
  Street: 'Ulice',
  Delivery: 'Doručení',
  'Delivery service': 'Doručovací služba',
  'Delivery type': 'Typ doručení',
  Phone: 'Telefon',
  'Payment information': 'Informace o platbě',
  Payment: 'Platba na kartu prodejce',
};
