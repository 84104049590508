export const FormBillingDetailsTranslationsEn = {
  invoiceDetails: 'Invoice Details',
  Name: 'Name',
  Street: 'Street',
  City: 'City',
  ZIP: 'ZIP',
  Country: 'Country',
  NonVATPayer: 'Non-VAT Payer',
  IdentifiedPerson: 'Identified Person',
  VATPayer: 'VAT Payer',
  CompanyID: 'IČO',
  VATID: 'DIČ',
  Continue: 'CONTINUE',
  Back: 'BACK',
};
