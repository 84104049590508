export const ModalRegisterTranslationsEn = {
  Registration: 'Registration',
  Authorization: 'Authorization',
  'Register with phone number or email': 'Register with phone number or email',
  Email: 'Email',
  'Phone Number': 'Phone Number',
  Or: 'Or',
  Facebook: 'Facebook',
  Google: 'Google',
  Other: 'Other',
};
