export const NavigationOverlayTranslationsEn = {
  home: 'Home',
  womenClothing: "Women's Clothing",
  menSuits: "Men's Suits",
  childrenClothing: "Children's Clothing",
  decorAndToys: 'Decor and Toys',
  aboutUs: 'About Us',
  account: 'Account',
  profile: 'Profile',
  chat: 'Chat',
  favorite: 'Favorite',
  offer: 'Offer',
  addProduct: 'Add Product',
  rentIn: 'Rent In',
  myOrders: 'My Purchases',
  myPurchases: 'My Sales',
  wallet: 'My Wallet',
  basket: 'Basket',
  cardProduct: 'Product card',
  edit: 'Editing',
};
