export const SectionAboutAniraKTranslationsUk = {
  'Platform ANIRAK': 'ПЛАТФОРМА ANIRAK - ЦЕ',
  rent: 'орендувати',
  'Wardrobe expansion': 'Розширення гардеробу',
  'Description wardrobe expansion':
    'Можливість виглядати бездоганно при цьому, не витрачаючи багато грошей на покупку та не обмежуючи себе в приємних емоціях від оновлення гардероба',
  'Passive income': 'Пасивний дохід',
  'Description passive income':
    'Монетизуючи свою шафу і здаючи в оренду свої вишукані речі ви заробляєте додаткові кошти',
  'Economic benefit': 'Економічна вигода',
  'Description economic benefit':
    'Оренда вишуканого і брендового одягу - гарна альтернатива покупці нового одягу для ваших особливих подій',
  'Safe usage': 'Безпечне користування',
  'Description safe usage':
    'Завдяки інноваційним процесам авторизації (перевірки особи) та рейтингам користувачів — платформа контролює рівень безпеки та обмежує можливі шахрайства',
  'Helping the planet': 'Допомога планеті',
  'Description of helping':
    ' Свідоме циклічне споживання — це турбота про екологію та реальне зменшення негативного впливу на навколишнє середовище',
};
