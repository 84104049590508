export const FilterDecorTranslationsUk = {
  Decor: 'Декор',
  accessories: 'Аксесуари',
  birthday: 'День народження',
  Christmas: 'Різдво',
  'Theme-holidays': 'Тематичні свята',
  textile: 'Текстиль',
  'blankets-for-baby': 'Пледи для малюків',
  linens: 'Постільна білизна',
};
