export const FilterDecorTranslationsCs = {
  Decor: 'Dekor',
  accessories: 'Doplňky',
  birthday: 'Narozeniny',
  Christmas: 'Vánoce',
  'Theme-holidays': 'Tematické svátky',
  textile: 'Textil',
  'blankets-for-baby': 'Obyčejné deky pro děti',
  linens: 'Povlečení',
};
